import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function Shop() {
  return (
    <Layout>
      <SEO
        keywords={[`black`, `black women`, `domain names`, `websites`, `easy website`, `black hair`]}
        title="Shop"
      />
      <section>
        <h1>Buy Black</h1>
      </section>
    </Layout>
  );
}

export default Shop;
